import React, { Component } from 'react';


class Spinner extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        return (
            <React.Fragment>
                <div className="col-12 pl-0 pr-0 d-flex justify-content-center pt-3 pb-3">
                <div className="loadingio-spinner-spinner-rbgsrqbevss"><div className="ldio-gchfd7vk7kk">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div></div>
                </div>

            </React.Fragment>
        )
    }


} //  end class

export default Spinner