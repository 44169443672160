import React, { } from "react";

const Button = (props) => {

  const { label, onClickButton } = props

  return (
    <div>
      <button className='button-submit' onClick={onClickButton}>
        {label}
      </button>
    </div>
  )
}

Button.defaultProps = {
  label: 'ตอบกลับ',
}

export default Button