import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import configureStore from "./redux/store";


try{
    serviceWorker.unregister();
}catch(ex){

}




ReactDOM.render(
  <React.Fragment>
    <div className="root-container">
      <Provider store={configureStore} >
        <Router>
           <Route exact path="/ticket/:role/:id" component={App} />
    
        </Router>
      </Provider>
    </div>


  </React.Fragment>
  ,

  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
