import apiClass from '../helper/api'
import { deleteCookieData } from '../helper/cookieHelper'

class CaseProvider extends apiClass {
  constructor() {
    super({
      restPath: '/cases',
    })
  }

  getCaseByLifeKey = async (id) => {
    try {
      // to do
      const res = await this.get({ path: `/${id}` })
      return res
    } catch (error) {


      if (error.response.data.code === 401) {
        deleteCookieData('token')
        setTimeout(() => {
          window.location.reload()
        }, 2000);

      }

      throw error
    }
  }
  
  closeCase = async(id) => {
    try {
        // to do
        const res = await this.patch({ path: `/close/${id}` })
        return res
      } catch (error) {
         throw error.response.data
      }

  }

}

export default new CaseProvider()
