import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import {
  SET_REPLIES_FILED,
  CREATE_REPLIES_SUPPORT,
  CREATE_REPLIES_CUSTOMER,
} from '../actions/replies'
import { SET_CASES_FIELD } from '../actions/cases'
import repliesProvider from '../../provider/repliesProvider'
import uploadProvider from '../../provider/uploadProvider'

export function* createRepliesForSupport(action) {
  const {
    payload: { lifeKey, body },
  } = action

  try {
    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalLoading',
        value: true,
      },
    })

    const files = []

    for (const i in body.attatchment) {
      if (body.attatchment.hasOwnProperty(i)) {
        const resFile = yield call(uploadProvider, body.attatchment[i].file)

        const tmpFile = {
          attachType: 'link',
          uploadName: resFile.fileName,
          uploadRawName: resFile.originalFileName,
          uploadPath: `image/${resFile.fileName}`,
          uploadSize: resFile.size,
        }

        files.push(tmpFile)
      }
    }

    const data = {
      ...body,
      attatchment: files,
    }

    const res = yield call(
      repliesProvider.createRepliesForSupport,
      lifeKey,
      data,
    )

    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalLoading',
        value: false,
      },
    })

    yield put({
      type: SET_CASES_FIELD,
      payload: {
        key: "labelMessage",
        value: 'ตอบกลับสำเร็จ'
      }
    })

    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalSuccess',
        value: true,
      },
    })
  } catch (ex) {
    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalLoading',
        value: false,
      },
    })
    console.log('ERRRRR', ex)
    yield put({
      type: SET_CASES_FIELD,
      payload: {
        key: "labelMessageError",
        value: 'ตอบกลับไม่สำเร็จ'
      }
    })
    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalError',
        value: true,
      }
    })
  }
}

export function* createRepliesForCustomer(action) {
  const {
    payload: { lifeKey, body },
  } = action

  try {
    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalLoading',
        value: true,
      },
    })

    const files = []

    for (const i in body.attatchment) {
      if (body.attatchment.hasOwnProperty(i)) {
        const resFile = yield call(uploadProvider, body.attatchment[i].file)

        const tmpFile = {
          attachType: 'link',
          uploadName: resFile.fileName,
          uploadRawName: resFile.originalFileName,
          uploadPath: `image/${resFile.fileName}`,
          uploadSize: resFile.size,
        }

        files.push(tmpFile)
      }
    }

    const data = {
      ...body,
      attatchment: files,
    }

    const res = yield call(
      repliesProvider.createRepliesForCustomer,
      lifeKey,
      data,
    )

    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalLoading',
        value: false,
      },
    })

    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalSuccess',
        value: true,
      },
    })
  } catch (ex) {
    console.log('ERRRRR', ex)
    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalLoading',
        value: false,
      },
    })
    yield put({
      type: SET_CASES_FIELD,
      payload: {
        key: "labelMessageError",
        value: 'ตอบกลับไม่สำเร็จ'
      }
    })
    yield put({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalError',
        value: true,
      }
    })
  }
}

export default function* useWatcher() {
  yield takeLatest(CREATE_REPLIES_SUPPORT, createRepliesForSupport)
  yield takeLatest(CREATE_REPLIES_CUSTOMER, createRepliesForCustomer)
}
