import { SET_REPLIES_FILED } from '../actions/replies'

const initialState = {
    isShowModalLoading: false,
    isShowModalSuccess: false,
    isShowModalError: false,
};

export default function (state = initialState, action) {

    const { type, payload } = action
    switch (type) {

        case SET_REPLIES_FILED: {
            const { key, value } = payload
            return {
                ...state,
                [key]: value
            }
        }

        default: return state
    }
}