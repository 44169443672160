import axios from 'axios'
import host from '../config/host'

const UploadProvider = async (file) => {

  try {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('serviceKey', host.IMAGESERVICEKEY)
    formData.append('secretKey', host.SECRETKEY)

    const path = host.API_MEDIA + `/upload`
    const res = await axios.post(path, formData)

    return res.data
  } catch (error) {
    console.log('err', error)
    throw error
  }
}

export default UploadProvider
