import apiClass from '../helper/api'

class RepliesProvider extends apiClass {
  constructor() {
    super({
      restPath: '/replies',
    })
  }

  createRepliesForSupport = async (lifeKey, data) => {
    try {
      // to do
      const res = await this.post({ path: `/support/${lifeKey}`, data })
      return res
    } catch (error) {
      return error
    }
  }

  createRepliesForCustomer = async (lifeKey, data) => {
    try {
      // to do
      const res = await this.post({ path: `/customer/${lifeKey}`, data })
      return res
    } catch (error) {
      return error
    }
  }
}

export default new RepliesProvider()
