import React from 'react';
import './App.css';
// import Main from './pages/main'
import Ticket from './pages/ticket'
import "../node_modules/bootstrap/dist/css/bootstrap.css"
import "./styles/base.scss"



function App() {
    return (
      <React.Fragment>
        <div className="App">
             <Ticket />
        </div>
      </React.Fragment>
    );
  }

export default App;
