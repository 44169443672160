import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import iconRemove from '../images/icon/icon-remove.svg'
import '../styles/modal.scss'

const ModalPreview = (props) => {
  const { closeModal, children, isOpenModal } = props

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpenModal)
  }, [isOpenModal])

  const onCloseModal = () => {
    setIsOpen(false)
    closeModal()
  }

  return (
    <Modal className='modal-preview-media' isOpen={isOpen} backdrop={true}>
      <ModalHeader>
        <div className='icon-close-box' onClick={onCloseModal}>
          <i className="icon-cancel"></i>
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}

ModalPreview.defaultProps = {
  closeModal: () => { },
  isOpenModal: false,
}

export default ModalPreview
