import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { TicketForm, Message, ModalSuccess, ModalError, ModalLoading, ModelCloseCases } from '../components'

import { dispatchCases, FETCH_CASES, SET_CASES_FIELD, UPDATE_CLOSE_CASES } from '../redux/actions/cases'
import { dispatchReplies, SET_REPLIES_FILED } from '../redux/actions/replies'
import { dispatchUser, SET_USER } from '../redux/actions/user'

import { readCookie } from '../helper/cookieHelper'

import Logo from '../images/Outlet-live.png'
import '../styles/ticket.scss'

const Ticket = (props) => {
  const {
    match: {
      params: { role, id },
    },
    dispatchCases,
    dispatchReplies,
    dispatchUser,
    caseDetail,
    isShowModalLoading,
    isShowModalSuccess,
    isShowModalError,
    isShowModalCloseCase,
    labelMessage,
    labelMessageError,
  } = props

  useEffect(() => {
    if (role === 'support' && !readCookie('token')) {
      dispatchUser({
        type: SET_USER,
        payload: {
          lifeKey: id,
        },
      })
    } else {
      dispatchCases({
        type: FETCH_CASES,
        payload: {
          lifeKey: id,
        },
      })
    }
  }, [])

  const onCilckConfirm = () => {
    dispatchReplies({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalSuccess',
        value: false,
      },
    })
    dispatchCases({
      type: FETCH_CASES,
      payload: {
        lifeKey: id,
      },
    })
  }

  const onCilckConfirmError = () => {
    dispatchReplies({
      type: SET_REPLIES_FILED,
      payload: {
        key: 'isShowModalError',
        value: false,
      },
    })
  }

  const onCilckCancle = () => {
    dispatchCases({
      type: SET_CASES_FIELD,
      payload: {
        key: "isShowModalCloseCase",
        value: false
      },
    })
  }

  const updateCloseCases = () => {
    const { id } = caseDetail.case
    dispatchCases({
      type: UPDATE_CLOSE_CASES,
      payload: {
        caseId: id
      },
    })
  }

  const showModelCloseCases = () => {
    dispatchCases({
      type: SET_CASES_FIELD,
      payload: {
        key: "isShowModalCloseCase",
        value: true
      },
    })
  }

  const Title = ({ label, data }) => <label className='text-ticket-info'>{`${label} : ${data}`}</label>

  return (
    caseDetail && (
      <div className='container-ticket'>
        <div className='box-header-ticket'>
          <div className='box-img-logo'>
            <img src={Logo} />
          </div>
        </div>
        <div className='box-title-ticket container mt-0 pt-0'>
          <div className='box-text-title'>
            <label className='text-title'>ตอบกลับปัญหา</label>
          </div>
          <div className='box-text-ticket-info'>
            <Title label='Ticket ID' data={caseDetail?.case?.caseNumber} />
            <Title label='ประเภทของปัญหา' data={caseDetail?.case?.subject} />
            <Title label='หมายเลขคำสั่งซื้อ' data={caseDetail?.case?.refNumber1} />
            <Title label='ชื่อผู้ติดต่อ' data={caseDetail?.case?.contactName} />
            <Title label='อีเมลติดต่อ' data={caseDetail?.case?.contactEmail} />
          </div>
        </div>
        <Message data={caseDetail.case} title={caseDetail.case.contactEmail} />
        {caseDetail.replies.map(item => {
          return (
            <Message key={item.lifeKey} data={item} title={item.userId ? 'OutletLive' : caseDetail.case.contactEmail} />
          )
        })
        }
        {caseDetail.case.closedDate == null ?
          <div>
            {caseDetail?.isReply ?
              <div className='container mt-0 pt-0'>
                <TicketForm role={role} dataCase={caseDetail.case} lifeKey={id} />
              </div> :
              <div>
                {role === 'support' ?
                  <div>
                    <div className='box-button-submit-support'>
                      <button className='button-close-case' onClick={showModelCloseCases}>ปิดเคส</button>
                    </div>
                    <div className="box-text-waitmessage">
                      <label>ส่งข้อความตอบกลับแล้ว<br />กรุณารอการตอบกลับผ่านอีเมล</label>
                    </div>
                  </div>
                  :
                  <div className="box-text-waitmessage">
                    <label>ส่งข้อความตอบกลับแล้ว<br />กรุณารอการตอบกลับผ่านอีเมล</label>
                  </div>
                }
              </div>
            }
          </div>
          :
          <div className="box-text-waitmessage">
            <label className="py-2">ปิดเคสเรียบร้อยแล้ว</label>
          </div>
        }
        <ModalSuccess label={labelMessage} isShowModal={isShowModalSuccess} clickConfirm={onCilckConfirm} />
        <ModalError label={labelMessageError} isShowModal={isShowModalError} clickConfirm={onCilckConfirmError} />
        <ModelCloseCases isShowModal={isShowModalCloseCase} clickCancle={onCilckCancle} clickConfirm={updateCloseCases} />
        <ModalLoading isShowModal={isShowModalLoading} />
      </div>
    )
  )
}

const mapDispatchToProps = {
  dispatchCases: dispatchCases,
  dispatchReplies: dispatchReplies,
  dispatchUser: dispatchUser,
}

const mapStateToProps = (state) => ({
  caseDetail: state.cases.caseDetail,
  isShowModalLoading: state.replies.isShowModalLoading,
  isShowModalSuccess: state.replies.isShowModalSuccess,
  isShowModalError: state.replies.isShowModalError,
  isShowModalCloseCase: state.cases.isShowModalCloseCase,
  labelMessage: state.cases.labelMessage,
  labelMessageError: state.cases.labelMessageError,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ticket))
