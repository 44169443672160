import { all } from "redux-saga/effects";
import cases from './cases'
import replies from './replies'
import user from './user'


export default function* rootSaga() {
  yield all([
    cases(),
    replies(),
    user()
  ]);
}
