import axios from 'axios'
import host from '../config/host'
import { setCookieData } from '../helper/cookieHelper'

const UserProvider = async () => {
  try {
    const body = {
      email: 'admin@supportcenter.com',
      password: 'adminsupportcenter',
    }

    const path = host.api + `/auth/login`
    const res = await axios.post(path, body)

    setCookieData('token', res.data.token.accessToken)


    window.location.reload()

    return res.data
  } catch (error) {
    console.log('err', error)
    
    throw error
  }
}

export default UserProvider
