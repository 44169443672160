import React, { useState } from 'react'
import classnames from 'classnames'

const Textarea = (props) => {
  const { label, defaultValue, onChangeTextarea, placeholder, error } = props

  const [value, setValue] = useState(defaultValue)

  const onChangeText = (e) => {
    setValue(e.target.value)
    onChangeTextarea(e.target.value)
  }

  return (
    <div className='textarea-field'>
      <label>
        {label}
        <span className='require'> *</span>
      </label>
      <div className={classnames('container-input', { error: !!error })}>
        <textarea
          className='input-box'
          placeholder={placeholder}
          onChange={onChangeText}
          value={value}
          rows='3'
        />
      </div>
      {error && <div className='error-msg'>{error}</div>}
    </div>
  )
}

Textarea.defaultProps = {
  label: 'ชื่ออัลบั้ม',
  defaultValue: '',
  placeholder: '',
  handleChangeText: () => {},
}

export default Textarea
