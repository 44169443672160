import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { SET_USER } from '../actions/user'
import { FETCH_CASES } from '../actions/cases'
import userProvider from '../../provider/userProvider'

export function* login(action) {
  const { payload } = action

  try {
    yield call(userProvider)

    yield put({
      type: FETCH_CASES,
      payload,
    })
  } catch (ex) {
    console.log('ERRRRR', ex)
  }
}

export default function* useWatcher() {
  yield takeLatest(SET_USER, login)
}
