// export const FETCH_AUTO_USER         =  "FETCH_AUTO_USER"
// export const FETCH_REQUEST_OTP       =  "FETCH_REQUEST_OTP"
// export const FETCH_VERIFY_OTP        =  "FETCH_VERIFY_OTP"
export const SET_USER = 'SET_USER'
// export const SET_USER_FIELD_INPUT    =   "SET_USER_FIELD_INPUT"
// export const SET_USER_FILED          =   "SET_USER_FILED"
// export const SET_SHOW_MODAL_ERR      =   "SET_SHOW_MODAL_ERR"
// export const SET_VERIFY_OTP          =   "SET_VERIFY_OTP"
// export const SET_ERROR_OTP           =   "SET_ERROR_OTP"
// export const SET_LINE_PROFIE                  =  "SET_LINE_PROFIE"
// export const INIT_USER_STATE                    = "INIT_USER_STATE"
// export const SET_USER_TEMP                      =   "SET_USER_TEMP"
// export const SET_SHOW_MODAL_LOGIN               =   "SET_SHOW_MODAL_LOGIN"
// export const UPDATE_AGREEMENT_TERM              =  "UPDATE_AGREEMENT_TERM"
// export const GET_USER_INFO                      = "GET_USER_INFO"
// export const RESET_FORM_LOGIN                   = "RESET_FORM_LOGIN"
// export const SET_USER_INFO                      = "SET_USER_INFO"
// export const SET_USER_PROFILE             = "SET_USER_PROFILE"
// export const SET_ERROR_REQ_OTP          =   "SET_ERROR_REQ_OTP"

export function dispatchUser(param) {
  return {
    type: param.type,
    payload: param.payload,
  }
}
