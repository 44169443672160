import { combineReducers } from 'redux';
import user from "./user"
import cases from "./cases"
import replies from "./replies"

const rootReducer = combineReducers({
   user,
   cases,
   replies,
});

export default rootReducer;
