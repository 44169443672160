import React, { useState } from 'react'
import ModalPreview from './ModalPreview'
import host from '../config/host'
import { displayDateTh } from '../helper/timeUtil'
import '../styles/message.scss'

const Message = (props) => {

  const [isOpen, setIsOpen] = useState(false)

  const { data, title } = props

  return (
    <div className='box-massage-ticket container mt-0 pt-0'>
      <div className='inbox-massage-ticket'>
        <div className={data.userId ? 'box-message-admin' : 'box-message'}>
          <div className='header-message'>
            <div className='box-message-user'>
              <label className={data.userId ? 'text-email-admin' : 'text-email-user'}>{title}</label>
            </div>
            <div className='box-message-date'>
              <label className='text-date-user'>{displayDateTh(data.createdDate)}</label>
            </div>
          </div>
          <div className='content-message'>
            <div className='box-message-content'>
              <label className='text-content'>{data.description}</label>
            </div>
            <div className='d-flex'>
              {data.attachments.map((item,idx) =>
                <div key={idx}>
                  <div  className='box-message-img' onClick={() => setIsOpen(true)}>
                    <img src={host.image + item.uploadPath} />
                  </div>
                  <ModalPreview
                    closeModal={() => setIsOpen(false)}
                    isOpenModal={isOpen}
                  >
                    <img alt='name' className='img-preview' src={host.image + item.uploadPath} />
                  </ModalPreview>
                </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Message
