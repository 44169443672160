export default {
  host: process.env.REACT_APP_API_HOST,
  api: process.env.REACT_APP_API_ENDPOINT,
  // apiLogistic : process.env.REACT_APP_API_LOGISIC_HOST,
  image: 'https://thumb.izcene.com/saranros/',
  img: 'https://thumb.izcene.com/saranros/800x600/image/',
  avatar: 'https://thumb.izcene.com/saranros/300x300/image/',
  cover: 'https://thumb.izcene.com/saranros/1024x576/image/',
  banner: 'https://thumb.izcene.com/saranros/0x0/image/',
  SERVICEKEY: process.env.REACT_APP_SERVICEKEY,
  IMAGESERVICEKEY: process.env.REACT_APP_IMAGE_SERVICEKEY,
  SECRETKEY: process.env.REACT_APP_SECRETKEY,
  API_MEDIA: process.env.REACT_APP_API_MEDIA,
}

