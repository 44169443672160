import { takeLatest, put, call, select, delay } from "redux-saga/effects";
import { FETCH_CASES, SET_CASES, UPDATE_CLOSE_CASES, SET_CASES_FIELD } from '../actions/cases'
import { SET_REPLIES_FILED } from '../actions/replies'
import caseProvider from '../../provider/caseProvider'

export function* onFetchCaseByLifeKey(action) {
    const { payload: { lifeKey } } = action

    try {
        const res = yield call(caseProvider.getCaseByLifeKey, lifeKey)

        yield put({
            type: SET_CASES,
            payload: {
                "caseDetail": res.data,
            }
        })

    } catch (ex) {
        console.log("ERRRRR", ex)
    }
}

export function* onUpdateCloseCase(action) {
    const { payload: { caseId } } = action
    try {
        yield call(caseProvider.closeCase, caseId) //  update case
        //TODO reload
        yield put({
            type: SET_CASES_FIELD,
            payload: {
                key: "isShowModalCloseCase",
                value: false
            }
        })
        yield put({
            type: SET_CASES_FIELD,
            payload: {
                key: "labelMessage",
                value: 'ปิดเคสสำเร็จ'
            }
        })

        yield put({
            type: SET_REPLIES_FILED,
            payload: {
                key: 'isShowModalSuccess',
                value: true,
            }
        })

    } catch (ex) {
        console.log(ex)
        yield put({
            type: SET_CASES_FIELD,
            payload: {
                key: "isShowModalCloseCase",
                value: false
            }
        })
        yield put({
            type: SET_CASES_FIELD,
            payload: {
                key: "labelMessageError",
                value: 'ปิดเคสไม่สำเร็จ'
            }
        })
        yield put({
            type: SET_REPLIES_FILED,
            payload: {
                key: 'isShowModalError',
                value: true,
            }
        })

    }
}


export default function* useWatcher() {
    yield takeLatest(FETCH_CASES, onFetchCaseByLifeKey)
    yield takeLatest(UPDATE_CLOSE_CASES, onUpdateCloseCase)
}
