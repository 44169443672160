import React, { useState } from 'react'
import classnames from 'classnames'
import ModalPreview from './ModalPreview'
import iconCamera from '../images/icon/icon-camera.svg'
import iconRemove from '../images/icon/icon-remove.svg'

const Upload = (props) => {
  const {
    inputFileRef,
    label,
    width,
    height,
    limitUpload,
    onRemoveImage,
    onSetImages,
    images,
  } = props

  const isShowBtnUpload = limitUpload > images.length

  return (
    <div className='upload-container'>
      <label>
        {label}
        <span className='require'> *</span>
      </label>
      <div className='list-image-container'>
        {isShowBtnUpload && (
          <ButtonUpload
            {...props}
            images={images}
            onSetImages={onSetImages}
            inputFileRef={inputFileRef}
          />
        )}

        {images.map((item, idx) => (
          <ImageCard
            key={idx}
            data={item}
            index={idx}
            onRemoveImage={onRemoveImage}
            width={width}
            height={height}
          />
        ))}
      </div>
    </div>
  )
}

const ButtonUpload = (props) => {
  const { width, height, disabled, onSetImages, images, inputFileRef } = props

  const onClickBrowse = (e) => {
    const checkType = ['image/jpeg', 'image/png']
    const file = e.target.files[0]

    if (file && checkType.includes(file.type)) {
      const reader = new FileReader()
      const tmpImages = [...images]

      reader.onloadend = () => {
        onSetImages([
          ...tmpImages,
          {
            file,
            preview: reader.result,
          },
        ])
      }

      reader.readAsDataURL(file)
    } else {
      alert('โปรดเลือกไฟล์ที่จะอัพโหลดให้ถูกต้อง')
    }
  }

  return (
    <div className='button-upload' style={{ width, height }}>
      <input
        ref={inputFileRef}
        type='file'
        onChange={onClickBrowse}
        className='input-file'
        accept='image/x-png, image/jpeg'
      />

      <img
        className={classnames('icon', { disabled: disabled })}
        src={iconCamera}
        alt=''
      />
    </div>
  )
}

const ImageCard = (props) => {
  const { data, width, height, onRemoveImage, index } = props
  
  const [isPreview, setPreview] = useState(false)

  return (
    <>
      <div
        className='image-container'
        style={{ width, height }}
        onClick={() => setPreview(true)}
      >
        <img src={data.preview} alt='' className='image' />
        <div className='icon-box' onClick={() => onRemoveImage(index)}>
          <img alt='' src={iconRemove} className='icon-remove' />
        </div>
      </div>
      <ModalPreview
        closeModal={() => setPreview(false)}
        isOpenModal={isPreview}
      >
        <img alt='name' className='img-preview' src={data.preview} />
      </ModalPreview>
    </>
  )
}

Upload.defaultProps = {
  onRemoveImage: () => {},
  onBrowse: null,
  label: 'อัปโหลดรูปภาพ',
  width: '8rem',
  height: '8rem',
  disabled: false,
  isShowButton: true,
  limitImageUpload: 4,
  inputFileRef: {},
}

export default Upload
