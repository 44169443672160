import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import iconError from '../images/icon-error.svg'
import '../styles/modalSuccess.scss'

const ModalError = (props) => {

  const { label, btnConfirm, isShowModal, clickConfirm } = props

  return (
    <Modal
      isOpen={isShowModal}
      // isOpen={true}
      className='box-modal-success'
    >
      <ModalHeader />
      <ModalBody>
        <div className="box-img-icon">
          <img src={iconError} alt="" />
        </div>
        <label>{label}</label>
      </ModalBody>
      <ModalFooter>
        <button className="btn" onClick={clickConfirm}>{btnConfirm}</button>
      </ModalFooter>
    </Modal>
  )

}

ModalError.defaultProps = {
  label: '',
  btnConfirm: 'ตกลง',
  isShowModal: false,
  clickConfirm: () => { }
}

export default ModalError
