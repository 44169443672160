import axios from 'axios';
import host from '../config/host' 
import apiKey from '../config/key'
import { readCookie } from '../helper/cookieHelper'


/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl = null) => {

  const options = {
    baseURL:  baseUrl ? baseUrl : host.api 
  };

 
  const token = readCookie('token') || apiKey.publicKeyAPI 

  options.headers = {
         Authorization: `Bearer ${token}`,
         servicekey : host.SERVICEKEY
  };

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    (requestError) => {

      return Promise.reject(requestError);
    },
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    (error) => {
    //   if (error.response.status >= 500) {

    //   }

      return Promise.reject(error);
    },
  );

  return client;
};

export default getClient