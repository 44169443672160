import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import '../styles/modal.scss'


const ModalLoading = (props) => {

  return (
    <Modal
      isOpen={props.isShowModal}
      className="modal-loading"
      backdrop={false}
    >
      <ModalBody>
        <div className="animate-loading bg-loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </ModalBody>
    </Modal>
  );
}

ModalLoading.defaultProps = {
  isShowModal: false,
}

export default ModalLoading

