import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import iconTrue from '../images/icon-true.svg'
import '../styles/modalCloseCases.scss'

const ModelCloseCases = (props) => {

  const { label, btnConfirm, btnCancel, isShowModal, clickConfirm, clickCancle } = props

  return (
    <Modal
      isOpen={isShowModal}
      className='box-modal-success'
    >
      <ModalHeader />
      <ModalBody>
        <label>{label}</label>
      </ModalBody>
      <ModalFooter>
        <div className="box-btn-case">
          <button className="btn btn-case-cancel" onClick={clickCancle}>{btnCancel}</button>
          <button className="btn btn-case-comfirm" onClick={clickConfirm}>{btnConfirm}</button>
        </div>
      </ModalFooter>
    </Modal>
  )

}

ModelCloseCases.defaultProps = {
  label: 'ยืนยันปิดเคส',
  btnConfirm: 'ตกลง',
  btnCancel: 'ยกเลิก',
  isShowModal: false,
  clickConfirm: () => { },
  clickCancle: () => { },
}

export default ModelCloseCases
