export const FETCH_CASES  =  "FETCH_CASES";
export const SET_CASES  =  "SET_CASES";
export const SET_CASES_FIELD  = "SET_CASES_FIELD";
export const UPDATE_CLOSE_CASES =  "UPDATE_CLOSE_CASES";

export  function dispatchCases(param){
  
    return {
      type: param.type,
      payload: param.payload,
    };
}