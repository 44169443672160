import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import Textarea from './Textarea'
import Upload from './Upload'
import Button from './Button'
import {
  dispatchReplies,
  CREATE_REPLIES_SUPPORT,
  CREATE_REPLIES_CUSTOMER,
} from '../redux/actions/replies'
import {
  dispatchCases,
  SET_CASES_FIELD,
} from '../redux/actions/cases'
import '../styles/ticketForm.scss'

const TicketForm = (props) => {
  const {
    dataCase,
    dispatchReplies,
    role,
    lifeKey,
    dispatchCases,
    caseDetail,

  } = props

  const [description, setDescription] = useState('')
  const [attatchment, setAttatchment] = useState([])
  const [error, setError] = useState('')

  const inputFileRef = useRef()

  const validator = () => {
    const hasMsg = description.trim()


    if (hasMsg) {
      setError('')
      return false
    } else {
      setError('กรุณากรอกข้อความตอบกลับ')
      return true
    }
  }

  const submitForm = () => {
    if (!validator()) {
      const body = {
        caseId: dataCase.id,
        refNumber1: dataCase.refNumber1,
        description: description,
        attatchment: attatchment,
      }

      dispatchReplies({
        type: role === 'support' ? CREATE_REPLIES_SUPPORT : CREATE_REPLIES_CUSTOMER,
        payload: {
          lifeKey,
          body,
        },
      })
    }
  }

  const changeTextarea = (value) => {
    setDescription(value)
  }

  const removeImage = (index) => {
    const tmpImage = [...attatchment]

    tmpImage.splice(index, 1)

    if (inputFileRef.current) {
      inputFileRef.current.value = ''
    }

    setAttatchment(tmpImage)
  }

  const setImages = (files) => {
    setAttatchment(files)
  }

  const showModelCloseCases = () => {
    dispatchCases({
      type: SET_CASES_FIELD,
      payload: {
        key: "isShowModalCloseCase",
        value: true
      },
    })
  }

  return (
    <div className='ticket-from'>
      <Textarea
        label='พิมพ์ข้อความตอบกลับ'
        placeholder='พิมพ์ข้อความตอบกลับ'
        onChangeTextarea={changeTextarea}
        value={description}
        error={error}
      />
      <Upload
        inputFileRef={inputFileRef}
        label='อัปโหลดรูปภาพ (ไม่เกิน 3 รูป รองรับไฟล์ .jpg .png)'
        limitUpload={3}
        onRemoveImage={removeImage}
        images={attatchment}
        onSetImages={setImages}
        width='8rem'
        height='8rem'
      />
      <div className='box-button-submit'>
        <Button label='ตอบกลับ' onClickButton={submitForm} />
        {role === 'support' ?
          <button className='button-close-case' onClick={showModelCloseCases}>ปิดเคส</button>
          :
          null
        }
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  dispatchReplies: dispatchReplies,
  dispatchCases: dispatchCases,
}

const mapStateToProps = (state) => ({
  replies: state.replies,
  caseDetail: state.cases.caseDetail,
})

export default connect(mapStateToProps, mapDispatchToProps)(TicketForm)
