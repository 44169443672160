import { SET_CASES, SET_CASES_FIELD } from '../actions/cases'

const initialState = {
    caseDetail: null,
    isShowModalCloseCase: false,
    isShowModalCloseCaseSuccess: false,
    labelMessage: 'ตอบกลับสำเร็จ',
    labelMessageError: 'ปิดเคสไม่สำเร็จ',
};

export default function (state = initialState, action) {

    const { type, payload } = action
    switch (type) {

        case SET_CASES_FIELD: {
            const { key, value } = payload
            return {
                ...state,
                [key]: value
            }
        }


        case SET_CASES: {
            const { caseDetail } = payload
            return {
                ...state,
                caseDetail,
            }
        }

        default: return state
    }
}