export const CREATE_REPLIES_SUPPORT = 'CREATE_REPLIES_SUPPORT'
export const CREATE_REPLIES_CUSTOMER = 'CREATE_REPLIES_CUSTOMER'
export const SET_REPLIES_FILED = 'SET_REPLIES_FILED'
export const SET_REPLIES = 'SET_REPLIES'

export function dispatchReplies(param) {

  return {
    type: param.type,
    payload: param.payload,
  }
}
